import React from "react";
import PropTypes from "prop-types";

/** Nextjs */

/** MUI */
import { alpha } from "@mui/system";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import useScrollTrigger from "@mui/material/useScrollTrigger";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "transparent", // alpha(theme.palette.primary.colors[900], 0.5),
        minHeight: 96,
        justifyContent: "center",
    },
    rootSemi: {
        backgroundColor: "rgba(0,26,42,0.75)",
        minHeight: 96,
        justifyContent: "center",
    },
    rootScroll: {
        backgroundColor: "rgba(0,26,42,1)",
        minHeight: 96,
        justifyContent: "center",
    },
}));

const Header = (props) => {
    const classes = useStyles();

    const handleGoTo = (href) => (window.location.href = href);
    return (
        <ElevationScroll {...props}>
            <AppBar position="fixed" elevation={0} className={classes.root}>
                <Toolbar
                    sx={{
                        justifyContent: { xs: "center", sm: "center", md: "space-between" },
                        pl: { xs: 0, sm: 0, md: "10%" },
                    }}
                >
                    <Box
                        component={"img"}
                        src={"/logo-xerone-750.png"}
                        sx={{
                            width: { xs: 240, md: 300 },
                            height: "auto",
                        }}
                        alt=""
                    />

                    <Box
                        sx={{
                            mr: 3,
                            color: "#FFF",
                            display: { xs: "none", sm: "none", md: "flex" },
                            flexGrow: 1,
                            justifyContent: "center",
                        }}
                    >
                        <Button size={"large"} color={"inherit"} onClick={() => handleGoTo("/")}>
                            {"About Us"}
                        </Button>
                        <Button size={"large"} color={"inherit"} onClick={() => handleGoTo("/dashboard")}>
                            {"Services"}
                        </Button>

                        <Button size={"large"} color={"inherit"} onClick={() => handleGoTo("/faq")}>
                            {"Solutions"}
                        </Button>

                        <Button size={"large"} color={"inherit"} onClick={() => handleGoTo("/faq")}>
                            {"Advantages"}
                        </Button>
                    </Box>

                    {/* <Box>
                    <Typography variant={"caption"} sx={{ mr: 1, textAlign: "center" }}>
                        <Button size={"small"} color={"secondary"} href={"/login"} fullWidth>
                            {"Login"}
                        </Button>
                    </Typography>
                </Box> */}
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    );
};

export default Header;

function ElevationScroll(props) {
    const { children, window } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger0 = useScrollTrigger({
        disableHysteresis: true,
        threshold: 50,
        target: window ? window() : undefined,
    });

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 500,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
        className: trigger0 ? (trigger ? classes.rootScroll : classes.rootSemi) : classes.root,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};
